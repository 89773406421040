import React from "react";

import { graphql } from "gatsby";
import PropTypes from "prop-types";

import AllComponents from "../content/AllComponents";
import Header from "../layouts/Header";
import MainLayout from "../layouts/MainLayout";
import Seo from "../Seo/Seo";

const ContactPage = ({ data: { page } }) => (
  <MainLayout
    variant="Black"
    header={(
      <Header
        headline={page.headline}
        bgImageUrl="url(/images/backgrounds/black.webp)"
        variant="overlay"
        theme="dark"
      />
    )}
  >
    {page.contentElements && (
      <AllComponents elements={page.contentElements} />
    )}
  </MainLayout>
);

export const query = graphql`
  query ($id: String!) {
    page: sanityContactPage(id: {eq: $id}) {
      id
      Metadata {
        ...MetaData
      }
      tagList {
        tags {
          url
          label
        }
        useJumpmarks
      }
      title
      slug {
        current
      }
      headline
      _rawDescription
      contentElements {
        ... on SanitySectionHeader {
          _key
          _type
          descriptionText
          headline
          color
          sectionLabel
          showCTA
          sectionID
          button {
            variant
            size
            color
            title
            icon
            internalLink {
              ... on SanityLegalDocument {
                id
                slug {
                  current
                }
              }
              ... on SanityService {
                id
                name
                variant
              }
            }
          }
        }
        ... on SanityServiceCardList {
          _key
          _type
          serviceCardList {
            background
            _rawDescription
            label
            variant
            button {
              variant
              size
              color
              title
              icon
              internalLink {
                ... on SanityLegalDocument {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  variant
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
              }
            }
            umbrellaContent {
              label
              tags {
                tags {
                  url
                  label
                }
                useJumpmarks
              }
            }
          }
        }
        ... on SanitySolutionCardList {
          _key
          _type
          showAs
          cardSize
          imagePosition
          solutionCardList {
            headline
            label
            background
            showButton
            image {
              ...ImageWithPreview
            }
            logo {
              ...ImageWithPreview
            }
            services {
              name
              variant
              lead {
                image {
                  ...ImageWithPreview
                }
                mail
                name
                telephone
                id
              }
            }
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
          }
        }
        ... on SanityOfficeCardList {
          _key
          _type
          officeCardList {
            areaTitle
            headline
            _rawAddress
            icon {
              _key
              _type
              _rawAsset
              _rawHotspot
              _rawCrop
              asset {
                url
              }
            }
            services {
              name
              variant
              lead {
                image {
                  _key
                  _type
                  _rawAsset
                  _rawHotspot
                  _rawCrop
                  asset {
                    url
                  }
                }
                mail
                name
                telephone
                id
              }
            }
          }
        }
        ... on SanityContentCardList {
          _key
          _type
          variant
          sectionID
          initiallyOpen
          actAsAccordion
          contentCardList {
            headline
            variant
            _rawDescription
            icon
            showRelatedLinks
            showPrimaryCTA
            primaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            showSecondaryCTA
            secondaryCTA {
              variant
              size
              color
              title
              icon
              external
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            relatedLinks {
              ... on SanityLegalDocument {
                id
                _type
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityService {
                _type
                id
                name
                variant
              }
            }
            overline {
              label
              variant
            }
            _rawDescription
          }
        }
        ... on SanityCTACard {
          _key
          _type
          sectionID
          variant
          image {
            asset {
              url
            }
          }
          label
          headline
          icon
          _rawDescription
        }
        ... on SanityContentSection {
          _key
          _type 
          color
          contentSections {
            _type
            headline
            variant
            button {
              variant
              size
              color
              title
              internalLink {
                ... on SanityLegalDocument {
                  _type
                  id
                  slug {
                    current
                  }
                }
                ... on SanityServiceDetail {
                  id
                  slug {
                    current
                  }
                }
                ... on SanityService {
                  id
                  name
                  _type
                }
              }
            }
            _rawDescription
            label
            image {
              ...ImageWithPreview
            }
            position
            relatedLinks {
              ... on SanityService {
                id
                name
                _type
              }
              ... on SanityHomepage {
                id
                slug {
                  current
                }
                title
              }
              ... on SanitySolutionDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityServiceDetail {
                id
                _type
                title
                slug {
                  current
                }
              }
              ... on SanityLegalDocument {
                id
                title
                slug {
                  current
                }
              }
            }
            umbrellaLabel
            variant
          }
        }
        ... on SanityContactSection {
          _key
          _type 
          title
          color
        }  
      }
    }
 }
`;

ContactPage.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const seo = ({ data }) => (
  <Seo data={data.page?.Metadata} />
);

seo.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const Head = seo;

export default ContactPage;
